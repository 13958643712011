import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ActivityType, Entities, Keyword} from 'types';
import {CreateEntityPageWrapper} from 'Widgets/CreateEntityPage/styled';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {ActionsBar} from 'Widgets/EntityPage/styled';
import KeywordsSelector from 'components/KeywordsSelector';
import {validateEntity} from 'utils/entityUtils';
import {entityRequest} from 'API/entityRequest';

const entity = Entities.Activities

type ActivityOption = {
  value: ActivityType,
  title: string
}

export const activityTypes: ActivityOption[] = [
  { value: 'ONLINE_COURSE', title: 'Онлайн-курс' },
  { value: 'DPO', title: 'ДПО' },
  { value: 'CONFERENCE', title: 'Конференция' },
  { value: 'CLUB', title: 'Клуб' },
  { value: 'VACANCY', title: 'Вакансия' },
  { value: 'EVENT', title: 'Мероприятие' },
  { value: 'OTHER', title: 'Другой' },
]

export type NewActivity = {
  keywords: Keyword[]
  description: string
  type: ActivityType | null
  name: string
  link: string
}

const newActivity: NewActivity = {
  keywords: [],
  description: '',
  type: null,
  name: '',
  link: ''
}
const ActivityCreator = <T, >() => {
  const navigate = useNavigate()
  const [newEntity, setNewEntity] = useState(newActivity)

  const setValue = <T extends keyof NewActivity, >(key: T, value: NewActivity[T]) => {
    setNewEntity(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSave = () => {
    const isEntityValid = validateEntity(entity, newEntity)
    if (!isEntityValid) {
      alert('Укажите все обязательные поля')
    } else {
      const result = window.confirm('Создать запись?')
      if (result) {
        entityRequest.createNewEntity(entity, newEntity).then(() =>
          navigate(`/${entity}/table`)
        )
      }
    }
  }

  return (
    <>
      <CreateEntityPageWrapper>
        <TextField
          error={!newEntity.name}
          variant="outlined"
          size="small"
          value={newEntity.name}
          label={'Название активность'}
          onChange={(e) => setValue('name', e.target.value)}
        />
        <TextField
          variant="outlined"
          size="small"
          value={newEntity.link}
          label={'Ссылка на активность'}
          onChange={(e) => setValue('link', e.target.value)}
        />

        <FormControl
          fullWidth size={'small'}
          error={!newEntity.type}
        >
          <InputLabel id="TypeSelect">Тип активности</InputLabel>
          <Select
            labelId={'TypeSelect'}
            variant="outlined"
            value={newEntity.type}
            label={'Тип активности'}
            onChange={(e) => setValue('type', e.target.value as ActivityType)}
          >
            {activityTypes.map(({ value, title }) =>
              <MenuItem value={value}>{title}</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          multiline
          variant="outlined"
          size="small"
          value={newEntity.description}
          label={'Описание активности'}
          rows={4}
          onChange={(e) => setValue('description', e.target.value)}
        />

        <KeywordsSelector
          value={newEntity.keywords}
          setValue={(keywords) => setValue('keywords', keywords)}
          error={!newEntity.keywords}
        />
      </CreateEntityPageWrapper>
      <ActionsBar>
        <Button variant="outlined" onClick={handleSave}>
          Сохранить
        </Button>
      </ActionsBar>
    </>
  );
};

export default ActivityCreator;
