import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {validateEntity} from 'utils/entityUtils';
import {entityRequest} from 'API/entityRequest';
import {CreateEntityPageWrapper} from 'Widgets/CreateEntityPage/styled';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {Activity, ActivityType, Entities} from 'types';
import KeywordsSelector from 'components/KeywordsSelector';
import {ActionsBar} from 'Widgets/EntityPage/styled';
import {activityTypes, NewActivity} from 'Widgets/CreateEntityPage/EntityCreators/ActivityCreator';
import Loader from 'UiKit/Loader';
import {request} from 'API/API';

const entity = Entities.Activities
const ActivityEditor = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id = Number(params.id)
  const [newEntity, setNewEntity] = useState<NewActivity&{id:number} | null>(null)

  const setValue = <T extends keyof NewActivity, >(key: T, value: NewActivity[T]) => {
    setNewEntity(prevState => prevState && ({ ...prevState, [key]: value }))
  }

  useEffect(()=>{
    request.get<Activity>(`${entity}/${id}`).then(res => setNewEntity(res.data))
  }, [newEntity?.id])

  const handleSave = () => {
    const isEntityValid = validateEntity(entity, newEntity)
    if (!isEntityValid) {
      alert('Укажите все обязательные поля')
    } else {
      const result = window.confirm('Создать запись?')
      if (result && newEntity) {
        entityRequest.editEntity(entity, id, newEntity).then(() =>
          navigate(`/${entity}/table`)
        )
      }
    }
  }

  return newEntity ?
    <>
      <CreateEntityPageWrapper>
        <TextField
          error={!newEntity.name}
          variant="outlined"
          size="small"
          value={newEntity.name}
          label={'Название мероприятия'}
          onChange={(e) => setValue('name', e.target.value)}
        />

        <TextField
          variant="outlined"
          size="small"
          value={newEntity.link}
          label={'Ссылка на мероприятие'}
          onChange={(e) => setValue('link', e.target.value)}
        />

        <FormControl
          fullWidth size={'small'}
          error={!newEntity.type}
        >
          <InputLabel id="TypeSelect">Тип мероприятия</InputLabel>
          <Select
            labelId={'TypeSelect'}
            variant="outlined"
            value={newEntity.type}
            label={'Тип мероприятия'}
            onChange={(e) => setValue('type', e.target.value as ActivityType)}
          >
            {activityTypes.map(({ value, title }) =>
              <MenuItem value={value}>{title}</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          error={!newEntity.description}
          multiline
          variant="outlined"
          size="small"
          value={newEntity.description}
          label={'Описание мероприятия'}
          rows={4}
          onChange={(e) => setValue('description', e.target.value)}
        />

        <KeywordsSelector
          value={newEntity.keywords}
          setValue={(keywords) => setValue('keywords', keywords)}
          error={!newEntity.keywords}
        />
      </CreateEntityPageWrapper>
      <ActionsBar>
        <Button variant="outlined" onClick={handleSave}>
          Сохранить
        </Button>
      </ActionsBar>
    </> : <Loader/>
};

export default ActivityEditor;