import React, {useState} from 'react';
import {RouterProvider} from "react-router-dom";
import {router} from "router/router";
import {createTheme, ThemeProvider} from "@mui/material";
import {ThemeProvider as StyledThemeProvider} from "styled-components";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: 'rgb(28,28,28)',
      paper: 'rgb(56,56,56)'
    },
    primary: {
      main: 'rgb(207,74,246)',
    }
  },
});

const password = "ITMO_TRACK"

function App() {
  const [isAuthorised, setIsAuthorized] = useState(!!localStorage.getItem('isAuthorised'));

  if (!isAuthorised) {
    const passwordAttempt = window.prompt("Какой пароль?")
    if (passwordAttempt === password) {
      setIsAuthorized(true)
      localStorage.setItem('isAuthorised', 'true')
    } else {
      setIsAuthorized(false)
    }
  }

  return (
    isAuthorised ?
      <ThemeProvider theme={darkTheme}>
        <StyledThemeProvider theme={darkTheme}>
          <RouterProvider router={router}/>
        </StyledThemeProvider>
      </ThemeProvider>
      : null
  );
}

export default App;
