import React from 'react';
import {Tab, Tabs} from "@mui/material";
import {Entities} from 'types';

export type TabType = {
  title: string,
  entity: Entities
}

type TabsProps = {
  tabs: TabType[],
  value: number,
  onTabClick: (newValue: number) => void
}

function createPropsFromIndex(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NavTabs = ({tabs, value, onTabClick}:TabsProps) => {

  return (
    <Tabs
      orientation="vertical"
      variant='fullWidth'
      value={value}
      indicatorColor="secondary"
      textColor="primary"
    >
      {tabs.map((tab, index)=>
        <Tab label={tab.title}
             key={tab.title}
             onClick={()=>onTabClick(index)}
             sx={{alignItems: 'flex-start'}}
             {...createPropsFromIndex(index)}
        />
      )}
    </Tabs>
  )
};

export default NavTabs;
