import React, {useEffect, useState} from 'react';
import {Button, IconButton, TextField} from "@mui/material";
import {Category, DefaultResponse, Entities, Keyword, Preset} from "types";
import {DataGrid, DataGridProps, GridColDef, GridPaginationModel, GridRowSelectionModel} from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {useNavigate, useParams} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {columnModelsByEntities} from "Widgets/EntityTable/constants";
import styled from "styled-components";
import {request} from "API/API";

const TableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px;
  background: ${({theme}) => theme.palette.background.default};
`

const EditColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

const ActionRow = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px;
  border-color: rgba(0, 0, 0, 0.6)
`
const DeleteButtonWrapper = styled.div<{ isopen: boolean }>`
  transform: scale(${({isopen}) => isopen ? 1 : 0});
  transform-origin: center;
  transition: all .15s linear;
`

const StyledDataGrid = styled(DataGrid)<DataGridProps>(() => ({
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
      outline: "none !important",
    },
}));

const getPageSize = (entity: Entities) => {
  if(entity === Entities.Keywords){
    return 100
  }
  return 50
}



const EntityTable = () => {
  const params = useParams();
  const entity = params.entity as Entities
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const [pageInfo, setPageInfo] = useState<DefaultResponse<Category | Preset | Keyword>>({
    count: 0,
    previous: null,
    next: null,
    results: []
  })
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: getPageSize(entity),
  });


  const getData = async (startLink?: string | null) => {
    setIsDataLoading(true)

    // const presetFilter = selectedPresets.length > 0 ? `presets=${selectedPresets.join(',')}` : null
    // const categoriesFilter = selectedCategories.length > 0 ? `categories=${selectedCategories.join(',')}` : null
    const textFilter = searchQuery.length > 0 ? `text=${searchQuery}` : null

    let url: string
    if (!startLink)
      url = `https://api.keywords.track.itmo.su/api/${entity}/?${[textFilter].filter(item => !!item).join('&')}`
    else {
      url = startLink + [textFilter].filter(item => !!item).join('&&')

      //ругаются корсы на редикрект, апи по https, а в ссылках прилетает http
      url = url.replace('http', 'https')
    }


    const {data} = await request.get(url)
    const {count, previous, next, results} = data
    setPageInfo({count, previous, next, results})
    if (previous === null)
      setPaginationModel( ({pageSize: results.length, page: 0}))

    setIsDataLoading(false)
  }

  const deleteEntity = (id: number) => {
    const isDeleteSubmitted = window.confirm('Удалить строку?')
    if(isDeleteSubmitted){
      request.delete(`${entity}/${id}`).then(()=>{
        getData()
      })
    }
  }

  const deleteSelectedEntities = () => {
    const isDeleteSubmitted = window.confirm('Удалить строки?')
    if(isDeleteSubmitted){
      Promise.all(rowSelectionModel.map((id)=>request.delete(`${entity}/${id}`))).then(()=>{
        getData()
      })}
  }

  useEffect(() => {
    getData()
  }, [entity, searchQuery])


  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    if (!isDataLoading) {
      getData(newPaginationModel.page > paginationModel.page ? pageInfo.next : pageInfo.previous)
      setPaginationModel(newPaginationModel);
    }
  };

  const editCell: GridColDef = {
    field: '', headerName: '', width: 120, sortable: false, disableColumnMenu: true,
    headerAlign: "center",
    renderHeader: () => (
          <DeleteButtonWrapper isopen={!!rowSelectionModel.length}>
            <IconButton onClick={deleteSelectedEntities}>
              <DeleteIcon/>
            </IconButton>
          </DeleteButtonWrapper>
    ),
    renderCell: (cell) =>
      <EditColumn>
        <IconButton
          title={'Изменить'}
          id="menu-button"
          onClick={() => cell.row.id && navigate(`/${entity}/${String(cell.row.id)}`)}
        >
          <EditOutlinedIcon/>
        </IconButton>
        <IconButton
          id="menu-button"
          title={'Удалить'}

          onClick={() => cell.row.id && deleteEntity(cell.row.id)}
        >
          <DeleteIcon/>
        </IconButton>
      </EditColumn>
  }

  return (
    <TableContainer>
      <ActionRow>
        <TextField
          key={entity}
          fullWidth
          id={'search'}
          label="Поиск"
          variant="outlined"
          size={'small'}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value)
          }
          }/>

        <Button
          variant="outlined"
          sx={{padding: '5px 7px', minWidth: 'unset'}}
          onClick={()=>navigate(`/${entity}/create`)}
        >
          <AddIcon/>
        </Button>

      </ActionRow>
      {!!entity &&
        <StyledDataGrid
          rows={pageInfo.results}
          columns={[...(columnModelsByEntities[entity]), editCell]}
          loading={isDataLoading}
          paginationMode={'server'}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[10]}
          paginationModel={paginationModel}
          rowCount={pageInfo.count}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
        />
      }

    </TableContainer>
  );
};

export default EntityTable;
