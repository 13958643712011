import React, {useState} from 'react';
import {Autocomplete, Chip, TextField} from "@mui/material";
import {KeywordBox} from "./styled";

type EntityWithId = {
  id: number | string
}

interface CustomAutocompleteProps<T> {
  value: T[]
  setValue: (item: T[]) => void
  getItemLabel: (item: T) => string
  onDelete: (item: T) => void
  getOptions: (query: string) => Promise<T[]>
  error?: boolean
}

const CustomAutocomplete = <T extends EntityWithId, >(props: CustomAutocompleteProps<T>) => {
  const {value, getItemLabel, onDelete, setValue, getOptions, error} = props
  const [options, setOptions] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [inputValue, setInputValue] = useState<string>('')
  const handleInputChange = (query: string) => {
    setInputValue(query)
      if(query.length > 0){
        setIsLoading(true)
        getOptions(query).then(options => {
          setOptions(options)
          setIsLoading(false)
        })
      } else {
        setOptions([])
      }
  }

  return (
    <div>
      <Autocomplete
        multiple
        size={'small'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        value={value}
        getOptionLabel={getItemLabel}
        disableCloseOnSelect
        loading={isLoading}
        onChange={(_, value) => {
          setValue(value)
        }}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField {...params} label="Ключевое слово" placeholder="Название" error={error}/>
        )}
        onInputChange={(_, value) =>
          handleInputChange(value)
        }
        inputValue={inputValue}
        style={{borderRadius: '0'}}
      />
      <KeywordBox >
        {value.map(item =>
          <Chip onDelete={() => onDelete(item)} key={item.id} label={getItemLabel(item)} variant="outlined"/>
        )}
      </KeywordBox>
    </div>
  );
};

export default CustomAutocomplete;
