import {request} from "./API";
import {NewActivity} from 'Widgets/CreateEntityPage/EntityCreators/ActivityCreator';
import {GenericNewEntity} from 'Widgets/CreateEntityPage';
import {DefaultResponse, Entities} from 'types';
import {makePostEntity} from 'utils/entityUtils';

export const entityRequest =   {
  getEntities: <T,>(entity: Entities) => {
    return request.get<DefaultResponse<T>>(entity)
  },
  createNewEntity: (entity: Entities, genericEntity: GenericNewEntity | NewActivity)=>{
    return request.post(entity+'/', makePostEntity(entity, genericEntity))
  },
  editEntity: (entity: Entities, id: number,  genericEntity: GenericNewEntity | NewActivity) => {
    return request.patch(`${entity}/${id}/`, makePostEntity(entity, genericEntity))
  }
}

