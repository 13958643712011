import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DefaultResponse, Entities, Keyword} from 'types';
import {BreadcrumbsContext} from 'context/BreadcrumbsContext';
import {request} from 'API/API';
import {validateEntity} from 'utils/entityUtils';
import {entityRequest} from 'API/entityRequest';
import {Box, Button, TextField, Typography} from '@mui/material';
import {ActionsBar, EntityPageWrapper} from 'Widgets/EntityPage/styled';
import CategorySelect from 'UiKit/CategorySelect';
import CustomAutocomplete from 'components/CustomAutocomplete';
import Loader from 'UiKit/Loader';
import {DefaultEntity} from 'Widgets/EntityPage/index';

const UniversalEditor = () => {
  const params = useParams()
  const entity = params.entity as Entities
  const id = Number(params.id)
  const { setTitle } = useContext(BreadcrumbsContext)
  const [entityToEdit, setEntityToEdit] = useState<DefaultEntity | null>(null)
  const navigate = useNavigate()
  useEffect(() => {
    request.get(`${entity}/${id}`).then(res => {
      // Пока поля у всех сущностей называются по рахзному, руками приведу ответ к дефолтной форме для редактирвоания
      const newEntity: DefaultEntity = {
        id: res.data.id,
        title: res.data?.text || res.data?.name || res.data.title
      }

      setTitle(newEntity.title)

      if (res.data.categories) {
        newEntity.categories = res.data.categories
      }

      if (res.data.keywords) {
        newEntity.keywords = res.data.keywords
      }

      if (res.data.child_keywords) {
        newEntity.child_keywords = res.data.child_keywords
      }

      setEntityToEdit(newEntity)

    })
  }, [])

  const handleKeywordChipDelete = (keywordToDelete: Keyword) => {
    if (entityToEdit?.keywords) {
      setEntityToEdit({
        ...entityToEdit,
        keywords: entityToEdit.keywords.filter(keyword => keyword.id !== keywordToDelete.id)
      })
    }
  }

  const handleChildrenKeywordChipDelete = (keywordToDelete: Keyword) => {
    if (entityToEdit?.child_keywords) {
      setEntityToEdit({
        ...entityToEdit,
        child_keywords: entityToEdit.child_keywords.filter(keyword => keyword.id !== keywordToDelete.id)
      })
    }
  }

  const getKeywords = (query: string) =>
    request.get<DefaultResponse<Keyword>>('/keywords/?text=' + query)
      .then(res =>
        res.data.results.filter(keyword => keyword.id !== id)
      )

  const handleSave = () => {
    if (entityToEdit) {
      const isEntityValid = validateEntity(entity, entityToEdit)
      if (!isEntityValid) {
        alert('Укажите все обязательные поля')
      } else {
        const result = window.confirm('Сохранить запись?')
        if (result) {
          entityRequest.editEntity(entity, id, entityToEdit).then(() =>
            navigate(`/${entity}/table`)
          )
        }
      }
    }
  }

  if (!(entity || id))
    return <Typography>Ошибочка</Typography>

  return (
    entityToEdit ?
      <>
        <EntityPageWrapper>
          <TextField
            fullWidth
            label="Название"
            variant="outlined"
            size={'small'}
            error={!entityToEdit.title}
            value={entityToEdit?.title}
            onChange={(event) => {
              setEntityToEdit({ ...entityToEdit, title: event.target.value })
            }
            }/>

          {entityToEdit.categories &&
              <CategorySelect
                  error={!entityToEdit.categories}
                  value={entityToEdit.categories}
                  setValue={(categories) =>
                    setEntityToEdit({
                      ...entityToEdit,
                      categories: categories
                    })}/>
          }

          {entityToEdit.keywords &&
              <Box sx={{ gap: '15px', display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: 'text.primary' }}>Ключевые слова</Typography>
                  <CustomAutocomplete
                      error={!entityToEdit.keywords}
                      value={entityToEdit.keywords}
                      setValue={(value) => setEntityToEdit({ ...entityToEdit, keywords: value })}
                      getItemLabel={(keyword) => keyword.text}
                      onDelete={handleKeywordChipDelete}
                      getOptions={getKeywords}/>
              </Box>
          }

          {entityToEdit.child_keywords &&
              <Box sx={{ gap: '15px', display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ color: 'text.primary' }}>Синонимы</Typography>
                  <CustomAutocomplete
                      value={entityToEdit.child_keywords}
                      setValue={(value) => setEntityToEdit({ ...entityToEdit, child_keywords: value })}
                      getItemLabel={(keyword) => keyword.text}
                      onDelete={handleChildrenKeywordChipDelete}
                      getOptions={getKeywords}/>
              </Box>
          }
        </EntityPageWrapper>
        <ActionsBar>
          <Button variant="outlined" onClick={handleSave}>
            Сохранить
          </Button>
        </ActionsBar>
      </>
      : <Loader/>
  )
};

export default UniversalEditor;