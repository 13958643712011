import React, {Fragment, useEffect, useState} from 'react';
import {Button} from "@mui/material";
import NavTabs, {TabType} from "./Tabs";
import LogoutIcon from '@mui/icons-material/Logout';
import styled from "styled-components";
import {useNavigate, useOutlet, useParams} from "react-router-dom";
import {Entities} from 'types';

export const tabs:TabType[] = [
  {title: 'Категории', entity: Entities.Categories},
  {title: 'Наборы навыков', entity: Entities.Presets},
  {title: 'Ключевые слова', entity: Entities.Keywords},
  {title: 'Активности', entity: Entities.Activities},
]

export const entityTitles: Record<Entities, string>= {
  categories: 'Категории',
  presets: 'Наборы навыков',
  keywords: 'Ключевые слова',
  activities: 'Активности'
}

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`

const NavContainer = styled.div`
  width: 200px;
  background-color: ${({theme}) =>theme.palette.background.paper};
  padding: 10px 0 10px;
  border-right: 1px;
  border-color: rgba(0, 0, 0, 0.6); 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`


const Layout = () => {
  const {entity} = useParams()
  const navigate = useNavigate()
  const outlet = useOutlet()
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(()=>{
    const index = tabs.findIndex(item=>item.entity === entity)

    if(index < 0){
      navigate(tabs[0].entity + '/table')
    }
    else{
      setActiveTabIndex(index)
    }
  })

  const handleChange = (newValue: number) => {
    setActiveTabIndex(newValue);
    navigate(tabs[newValue].entity + '/table')
  };

  return (
      <LayoutContainer>
        <NavContainer>
          <NavTabs tabs={tabs} value={activeTabIndex} onTabClick={handleChange}/>
          <Button onClick={()=> {
            localStorage.setItem('isAuthorised', '')
            window.location.reload()
          }} variant={'text'} endIcon={<LogoutIcon/>}>Выйти</Button>
        </NavContainer>
        <Fragment key={activeTabIndex}>
          {outlet}
        </Fragment>
      </LayoutContainer>
  );
};

export default Layout;
