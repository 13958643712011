import {createContext, Dispatch} from "react";

type BreadcrumbsContextType = {
  title: string
  setTitle: Dispatch<React.SetStateAction<string>>
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  title: '',
  setTitle: ()=>null
})

