import styled from "styled-components";
import {Breadcrumbs} from '@mui/material';

export const HeaderRow = styled.div`
  padding: 20px 22px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`

export const EntityLayoutWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({theme})=>theme.palette.background.default};
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding: 0 18px;
`