import React, {ReactNode} from 'react';
import {useParams} from "react-router-dom";
import {Category, Entities, Keyword} from 'types';
import UniversalEditor from 'Widgets/EntityPage/EntityEditors/UniversalEditor';
import ActivityEditor from 'Widgets/EntityPage/EntityEditors/ActivityEditor';

export type DefaultEntity = {
  id: number,
  title: string,
  categories?: Category[],
  keywords?: Keyword[]
  child_keywords?: Keyword[]
}

const EntityEditors: Record<Entities, ReactNode> = {
  categories: <UniversalEditor/>,
  keywords: <UniversalEditor/>,
  presets: <UniversalEditor/>,
  activities: <ActivityEditor/>
}


const EntityPage = () => {
  const params = useParams()
  const entity = params.entity as Entities

  return<>
    {EntityEditors[entity]}
  </>
}

export default EntityPage;
