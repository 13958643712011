import styled from "styled-components";

export const CreateEntityPageWrapper = styled.div`
  padding: 20px 40px 90px;
  max-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
