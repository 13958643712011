import {Category, Entities, Keyword} from 'types';


export const validateEntity = (entityName: Entities, entity: any) => {
  switch (entityName) {
    case "presets":
      return !!(entity.title.length && entity.categories?.length && entity.keywords?.length)
    case "keywords":
      return !!(entity.title.length)
    case "categories":
      return !!(entity.title.length)
    case "activities":
      return !!(entity.name.length && entity.keywords.length && entity.type)
    default:
      return false
  }
}

export const makePostEntity = (entityName: Entities, entity: any) => {
  switch (entityName) {
    case "presets":
      return {
        title: entity.title,
        keyword_ids: entity.keywords?.map((keyword: Keyword) => keyword.id),
        category_ids: entity.categories?.map((category: Category)=> category.id)
      }
    case "keywords":
      return {
        text: entity.title,
        child_keywords_ids: entity.child_keywords?.map((keyword: Keyword) => keyword.id)
      }
    case "categories":
      return {
        name: entity.title
      }
    case Entities.Activities:
      return {
        name: entity.name,
        description: entity.description,
        type: entity.type,
        keyword_ids: entity.keywords?.map((keyword: Keyword) => keyword.id),
        logo:"test",
        link: entity.link
      }
    default:
      return undefined
  }
}
