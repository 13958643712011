import styled from "styled-components";

export const ActionsBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 40px;
  display: flex;
  flex-direction: row-reverse;
  background-color: ${({theme}) =>theme.palette.background.paper};
  border-left: 2px solid ${({theme}) =>theme.palette.primary.main};
`

export const EntityPageWrapper = styled.div`
  padding: 20px 40px 90px;
  max-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
