export interface DefaultResponse<T>{
  count: number,
  next: string | null,
  previous: string | null,
  results: Array<T>
}

 export enum Entities {
  Categories='categories',
  Presets = 'presets',
  Keywords = 'keywords',
  Activities='activities'
}

export type ShortEntity = {
  id: number
  text: string
}

export type Keyword = {
  id: number,
  text: string
  child_keywords: Keyword[]
}

export type Preset = {
  id: number,
  categories: Category[],
  title: string,
}

export interface PresetDetails extends Preset {
  keywords: Keyword[]
}

export type Category = {
  id: number,
  name: string,
}

export type ActivityType = 'ONLINE_COURSE' | 'VACANCY' | 'EVENT' | 'OTHER' |  'DPO' | 'CONFERENCE' | 'CLUB'
export type Activity = {
  id: number
  name: string
  description: string,
  type: ActivityType,
  keyword_ids: number[],
  keywords: Keyword[],
  logo: string,
  created_at: string
  link: string
}
