import React from 'react';
import {DefaultResponse, Keyword} from 'types';
import CustomAutocomplete from 'components/CustomAutocomplete';
import {request} from 'API/API';

type KeywordsSelectorProps = {
  value: Keyword[]
  setValue: (value: Keyword[]) => void
  error?: boolean
}
const KeywordsSelector = ({ value, setValue, error }: KeywordsSelectorProps) => {
  const getKeywords = (query: string) => request.get<DefaultResponse<Keyword>>('https://api.keywords.track.itmo.su/api/keywords/?text=' + query).then(res => res.data.results)
  const handleKeywordChipDelete = (keywordToDelete: Keyword) => {
    setValue(value.filter(keyword => keyword.id !== keywordToDelete.id))
  }
  return (
    <CustomAutocomplete
      value={value}
      setValue={setValue}
      getItemLabel={(keyword) => keyword.text}
      onDelete={handleKeywordChipDelete}
      getOptions={getKeywords}
      error={error}
    />
  );
};

export default KeywordsSelector;