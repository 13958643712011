import {createBrowserRouter} from "react-router-dom";
import Layout from "../Layout";
import EntityTable from "../Widgets/EntityTable";
import EntityPage from "../Widgets/EntityPage";
import CreateEntityPage from "../Widgets/CreateEntityPage";
import EntityPageLayout from "../Layout/EntityPageLayout/EntityPageLayout";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        path: ':entity/table',
        element: <EntityTable/>
      },
      {
        path: ':entity/',
        element: <EntityPageLayout/>,
        children: [
          {
            path: ':id',
            element: <EntityPage/>
          },
          {
            path: 'create',
            element: <CreateEntityPage/>
          },
        ]
      },
    ]
  },
]);
