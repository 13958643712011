import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {DefaultResponse, Entities, Keyword} from 'types';
import {request} from 'API/API';
import {validateEntity} from 'utils/entityUtils';
import {entityRequest} from 'API/entityRequest';
import {CreateEntityPageWrapper} from 'Widgets/CreateEntityPage/styled';
import {Box, Button, TextField, Typography} from '@mui/material';
import CategorySelect from 'UiKit/CategorySelect';
import CustomAutocomplete from 'components/CustomAutocomplete';
import {ActionsBar} from 'Widgets/EntityPage/styled';
import {GenericNewEntity} from 'Widgets/CreateEntityPage/index';

const UniversalCreator = () => {
  const params = useParams()
  const entity = params.entity as Entities
  const navigate = useNavigate()
  const [newEntity, setNewEntity] = useState<GenericNewEntity>({
    title: ''
  })

  const handleKeywordChipDelete = (keywordToDelete: Keyword) => {
    if (newEntity?.keywords) {
      setNewEntity({ ...newEntity, keywords: newEntity.keywords.filter(keyword => keyword.id !== keywordToDelete.id) })
    }
  }

  const handleChildrenKeywordChipDelete = (keywordToDelete: Keyword) => {

    if (newEntity?.child_keywords) {
      setNewEntity({
        ...newEntity,
        child_keywords: newEntity.child_keywords.filter(keyword => keyword.id !== keywordToDelete.id)
      })
    }
  }

  const getKeywords = (query: string) => request.get<DefaultResponse<Keyword>>('https://api.keywords.track.itmo.su/api/keywords/?text=' + query).then(res => res.data.results)

  useEffect(() => {
    if (entity === 'presets' && !newEntity.keywords && !newEntity.categories) {
      setNewEntity(({ ...newEntity, categories: [], keywords: [] }))
    }
    if (entity === 'keywords' && !newEntity.child_keywords) {
      setNewEntity(({ ...newEntity, child_keywords: [] }))
    }
  })

  const handleSave = () => {
    const isEntityValid = validateEntity(entity, newEntity)
    if (!isEntityValid) {
      alert('Укажите все обязательные поля')
    } else {
      const result = window.confirm('Создать запись?')
      if (result) {
        entityRequest.createNewEntity(entity, newEntity).then(() =>
          navigate(`/${entity}/table`)
        )
      }
    }
  }

  return (
    <>
      <CreateEntityPageWrapper>
        <TextField
          error={!newEntity.title} variant="outlined" size="small"
          value={newEntity?.title}
          onChange={(e) => setNewEntity(prev => ({ ...prev, title: e.target.value }))}
          label={'Название'}
        />

        {newEntity.categories &&
            <CategorySelect
                error={!newEntity.categories.length}
                value={newEntity.categories}
                setValue={(categories) =>
                  setNewEntity({
                    ...newEntity,
                    categories: categories
                  })}/>
        }

        {newEntity.keywords &&
            <Box sx={{ gap: '15px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: 'text.primary' }}>Ключевые слова</Typography>
                <CustomAutocomplete
                    error={!newEntity.keywords.length}
                    value={newEntity.keywords}
                    setValue={(value) => setNewEntity({ ...newEntity, keywords: value })}
                    getItemLabel={(keyword) => keyword.text}
                    onDelete={handleKeywordChipDelete}
                    getOptions={getKeywords}/>
            </Box>
        }


        {newEntity.child_keywords &&
            <Box sx={{ gap: '15px', display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ color: 'text.primary' }}>Синонимы</Typography>
                <CustomAutocomplete
                    value={newEntity.child_keywords}
                    setValue={(value) => setNewEntity({ ...newEntity, child_keywords: value })}
                    getItemLabel={(keyword) => keyword.text}
                    onDelete={handleChildrenKeywordChipDelete}
                    getOptions={getKeywords}/>
            </Box>
        }
      </CreateEntityPageWrapper>
      <ActionsBar>
        <Button variant="outlined" onClick={handleSave}>
          Сохранить
        </Button>
      </ActionsBar>
    </>
  );
};

export default UniversalCreator;