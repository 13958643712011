import {GridColDef} from "@mui/x-data-grid";
import {ColumnModelsByEntities} from "./types";
import {Activity, ActivityType, Category, Keyword, Preset} from "types";
import dayjs from 'dayjs';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {IconButton} from '@mui/material';

export const activityTypesTitles: Record<ActivityType, string> = {
  ONLINE_COURSE: 'Онлайн-курс',
  VACANCY: 'Вакансия',
  EVENT: 'Мероприятие',
  OTHER: 'Другое',
  DPO: 'ДПО',
  CONFERENCE: 'Конференция',
  CLUB: 'Клуб',
}

export const categoryColumns: GridColDef<Category>[] = [
  { field: 'id', headerName: 'ID', width: 70, disableColumnMenu: true },
  { field: 'name', headerName: 'Название категории', flex: 2 }
]

export const presetColumns: GridColDef<Preset>[] = [
  { field: 'id', headerName: 'ID', width: 70, disableColumnMenu: true },
  { field: 'title', headerName: 'Название пресета', flex: 2 },
  {
    field: 'categories', headerName: 'Категории', flex: 1,
    valueGetter: (value) => {
      console.log(value)
      return value.row.categories.map(category => category.name).join(',')
    }
  },
]

export const keywordsColumns: GridColDef<Keyword>[] = [
  { field: 'id', headerName: 'ID', width: 70, disableColumnMenu: true },
  { field: 'text', headerName: 'Ключевое слово', flex: 2 },
  {
    field: 'child_keywords_id', headerName: 'Синонимы', flex: 1,
    valueGetter: (value) =>
      value.row.child_keywords.map(keyword => keyword.text).join(',')
  },
]

export const activitiesColumns: GridColDef<Activity>[] = [
  { field: 'id', headerName: 'ID', width: 70, disableColumnMenu: true },
  { field: 'name', headerName: 'Активность', flex: 2 },
  { field: 'description', headerName: 'Описание', flex: 2 },
  {
    field: 'type', headerName: 'Тип', width: 120,
    valueGetter: (value) => activityTypesTitles[value.row.type]
  },
  {
    field: 'link',
    headerName: 'Ссылка',
    align: 'center',
    width: 80,
    renderCell: (value) =>
      value.row.link
        ? <IconButton
          href={value.row.link} target='_blank' color="primary"
          size="small">
          <OpenInNewIcon/>
        </IconButton>
        : null
  },
  {
    field: 'created_at',
    headerName: 'Дата создания',
    width: 140,
    headerAlign: 'right',
    align: 'right',
    valueGetter: (value) => {
      const date = dayjs(value.row.created_at);
      return date.format('DD.MM.YYYY')
    }
  }
]


export const columnModelsByEntities: ColumnModelsByEntities = {
  'categories': categoryColumns,
  'presets': presetColumns,
  'keywords': keywordsColumns,
  'activities': activitiesColumns
}