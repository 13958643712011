import React, {ReactNode, useState} from 'react';
import {Link as RouterLink, useOutlet, useParams} from "react-router-dom";
import {Button, Link, Typography} from "@mui/material";
import TableChartIcon from "@mui/icons-material/TableChart";
import {entityTitles} from "../index";
import {EntityLayoutWrapper, HeaderRow, StyledBreadcrumbs} from "./styled";
import {Entities} from 'types';
import {BreadcrumbsContext} from 'context/BreadcrumbsContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type EntityPageLayoutProps = {
  children?: ReactNode
}
const EntityPageLayout = ({children}:EntityPageLayoutProps) => {
  const outlet = useOutlet()
  const params = useParams()
  const entity = params.entity as Entities

  const [title, setTitle] = useState<string>(entityTitles[entity])
  return (
    <EntityLayoutWrapper>
      <HeaderRow>
        <Button
          component={RouterLink}
          to={`/${entity}/table`}
          variant="text"
          startIcon={<ArrowBackIosIcon/>}
          size={'small'}
        >
          Назад
        </Button>
        <StyledBreadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            to={`/${entity}/table`}
            underline={'hover'}
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center',  }}
          >
            <TableChartIcon fontSize={'inherit'} sx={{ mr: 0.5 }}/>
            {entityTitles[entity]}
          </Link>
          <Typography color="text.primary">{title}</Typography>
        </StyledBreadcrumbs>
      </HeaderRow>
      <BreadcrumbsContext.Provider value={{title, setTitle}}>
        {outlet || children}
      </BreadcrumbsContext.Provider>
    </EntityLayoutWrapper>
  );
};

export default EntityPageLayout;
