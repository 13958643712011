import axios from "axios";

export const request = axios.create({
  baseURL: process.env.REACT_APP_KEYWORDS_API_URL
})

request.interceptors.response.use(response => response, error =>{
  if(error.message)
  alert(error.message)
  else
    alert('Ошибка')
})
