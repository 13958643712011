import styled from "styled-components";

export const KeywordBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  border:1px solid ${({theme}) =>theme.palette.background.paper};
  padding: 5px;
  border-radius: 0 0 10px 10px;
  border-top: none;
`
