import React, {ReactNode} from 'react';
import {useParams} from "react-router-dom";
import {Category, Entities, Keyword} from "types";
import UniversalCreator from 'Widgets/CreateEntityPage/EntityCreators/UniversalCreator';
import ActivityCreator from 'Widgets/CreateEntityPage/EntityCreators/ActivityCreator';

export type GenericNewEntity = {
  title: string
  categories?: Category[]
  keywords?: Keyword[]
  child_keywords?: Keyword[]
}

const EntityCreators: Record<Entities, ReactNode> = {
  categories: <UniversalCreator/>,
  keywords: <UniversalCreator/>,
  presets: <UniversalCreator/>,
  activities: <ActivityCreator/>
}


const CreateEntityPage = () => {
  const params = useParams()
  const entity = params.entity as Entities

  return<>
    {EntityCreators[entity]}
    </>
};

export default CreateEntityPage;
