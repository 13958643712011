import React, {useEffect, useState} from 'react';
import {Category, Entities} from "types";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {entityRequest} from "API/entityRequest";

type CategorySelectProps = {
  error?: boolean
  value: Category[],
  setValue: (value: Category[]) => void
}

const CategorySelect = ({ value, setValue, error }: CategorySelectProps) => {
  const [options, setOptions] = useState<Category[]>([])

  useEffect(() => {
    entityRequest.getEntities<Category>(Entities.Categories).then(({ data }) => setOptions(data.results))
  }, [])

  const hadleChange = (names: string[]) => {
    setValue(options.filter(option => names.includes(option.name)))
  }

  return (
    <FormControl size={'small'} sx={{ width: '100%' }}>
      <InputLabel id="category-select-label">Категории</InputLabel>
      <Select
        error={!!error}
        labelId="preset-select-label"
        id="preset-select"
        placeholder="Категории"
        label="Категории"
        multiple
        value={value.map(value => value.name)}
        onChange={(event) =>
          hadleChange(event.target.value as unknown as string[])
        }
      >
        {options ? options.map((option) =>
          <MenuItem value={option.name} key={option.name}>{option.name}</MenuItem>
        ) : <CircularProgress/>}
      </Select>
    </FormControl>
  );
};

export default CategorySelect;
